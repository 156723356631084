function Prevision(props) {
	props = props.info;
	const temp = Math.floor((props.main.temp - 273.15) * 10) / 10;
	const temp_max = Math.floor((props.main.temp_max - 273.15) * 10) / 10;
	const temp_min = Math.floor((props.main.temp_min - 273.15) * 10) / 10;

	const wind_speed = Math.floor(props.wind.speed * 3.6 * 10) / 10;
	const clouds = props.clouds.all;

	var emoji = "";

	switch (props.weather[0].description) {
		case "cielo sereno":
			emoji = <i class='bi bi-sun'></i>;
			break;
		case "nubi sparse":
			emoji = <i class='bi bi-cloud-sun'></i>;
			break;
		case "pioggia leggera":
			emoji = <i class='bi bi-cloud-drizzle'></i>;
			break;
		case "poche nuvole":
			emoji = <i class='bi bi-sun'></i>;
			break;
		case "cielo coperto":
			emoji = <i class='bi bi-clouds'></i>;
			break;
		case "forte pioggia":
			emoji = <i class="bi bi-cloud-rain-heavy"></i>;
			break;
		default:
			emoji = "";
			break;
	}

	return (
		<tr>
			<th scope='row'>
				{props.hour.toString().length === 1 ? `0${props.hour}` : props.hour}:00
			</th>
			<td>
				{temp <= 0 ? (
					<i class='bi bi-thermometer-snow'></i>
				) : temp >= 30 ? (
					<i class='bi bi-thermometer-sun'></i>
				) : (
					<i class='bi bi-thermometer-half'></i>
				)}{" "}
				{temp.toString().includes(".") ? temp : `${temp}.0`}°C
				<br />(<i class='bi bi-arrow-up-short'></i>
				{temp_max}
				<i class='bi bi-arrow-down-short'></i>
				{temp_min})
			</td>
			<td>
				{emoji} {props.weather[0].description[0].toUpperCase()}
				{props.weather[0].description.slice(1)}
			</td>
			<td>
				{props.pop === 0 ? (
					<i class='bi bi-slash-lg'></i>
				) : props.pop < 15 ? (
					<i class='bi bi-cloud-drizzle'></i>
				) : props.pop >= 70 ? (
					<i class='bi bi-cloud-rain-heavy'></i>
				) : (
					<i class='bi bi-cloud-rain'></i>
				)}{" "}
				{Math.floor(props.pop * 100 * 10)/10}%<br />
				{props.rain && <i class='bi bi-cloud-rain'></i>}
				{props.rain && " " + props.rain["3h"] + "mm"}
				{props.snow && <i class='bi bi-cloud-snow'></i> &&
					props.snow["3h"] + "mm"}
				{props.snow && " " + props.snow["3h"] + "mm"}
			</td>
			<td>
				{props.main.humidity < 15 ? (
					<i class='bi bi-droplet'></i>
				) : props.main.humidity >= 70 ? (
					<i class='bi bi-droplet-fill'></i>
				) : (
					<i class='bi bi-droplet-half'></i>
				)}{" "}
				{props.main.humidity}%
			</td>
			<td>
				<i class='bi bi-wind'></i> {wind_speed} km/h
			</td>
			<td>
				{clouds < 15 ? (
					<i class='bi bi-brightness-high'></i>
				) : clouds >= 70 ? (
					<i class='bi bi-clouds'></i>
				) : (
					<i class='bi bi-cloud-sun'></i>
				)}{" "}
				{clouds}%
			</td>
		</tr>
	);
}

export default Prevision;
