import { Route, Switch } from "react-router-dom";

import Header from "./components/Header";

import HomePage from "./pages/Home";
import CreditiPage from "./pages/Crediti";

function App() {
	return (
		<div>
			<Switch>
				<Route path='/' exact>
					<Header selected='home' />
					<HomePage />
				</Route>
				<Route path='/crediti' exact>
					<Header selected='crediti'/>
					<CreditiPage />
				</Route>
				<>
					<Header selected='404' />
					<div className="container">
						<p className="h1 text-center">404</p>
					</div>
				</>
			</Switch>
		</div>
	);
}

export default App;
