import PrevisionList from "../components/PrevisionList";
import Snippet from "../components/Snippet";

import { useState, useEffect, useRef } from "react";
import colors from "../colors";

function HomePage() {
	const [searchFormDown, setSearchFormDown] = useState(false);
	const [infos, setInfos] = useState(null);
	const cityRef = useRef("Roma");
	const cityRefTemp = useRef("Roma");
	const snippetRef = useRef("Roma");

	const citySnippetsClickHandler = (event) => {
		cityRef.current = event.target.dataset["city"];
		snippetRef.current = event.target.dataset["city"];
		getData();
	};

	const cityChangeHandler = (event) => {
		cityRefTemp.current = event.target.value;
		document.getElementById("comune-1").value = event.target.value;
		document.getElementById("comune-1").value = event.target.value;
	};

	const submitHandler = (event) => {
		event.preventDefault();
		cityRef.current = cityRefTemp.current;
		snippetRef.current = cityRef.current.toLowerCase();
		getData();
	};

	const getData = async () => {
		setInfos(false);
		const response = await fetch(
			`https://api.openweathermap.org/data/2.5/forecast?q=${cityRef.current}${
				cityRef.current.toLowerCase() === "roma" ? ",IT" : ""
			}&appid=f7ad92fed98b10bde3da2d0bf836efea&lang=it`
		);
		const data = await response.json();
		console.log(data);
		setInfos(data);
	};

	useEffect(() => {
		getData();

		window.addEventListener("scroll", () => {
			if (window.scrollY > 260) {
				setSearchFormDown(true);
			} else {
				setSearchFormDown(false);
			}
		});
	}, []);

	return (
		<>
			<div className='container'>
				<p className='h2'>Cerca il meteo per località</p>
				<form
					onSubmit={submitHandler}
					id='searchForm'
					className='row d-flex mb-3 mx-0 pb-2'>
					<div className='input-group col-8 col-md-10 col-lg-11 pl-0 pr-2'>
						<input
							type='text'
							onChange={cityChangeHandler}
							className='form-control'
							id='comune-1'
							name='comune'
							placeholder='Comune'
						/>
					</div>
					<button
						type='submit'
						className='btn btn-danger col-4 col-md-2 col-lg-1'
						style={{ background: colors.bg, border: "none" }}>
						Invia
					</button>
				</form>
				<div className='row container mb-5'>
					<Snippet
						snippetRef={snippetRef}
						clickEvent={citySnippetsClickHandler}
						cityName='roma'></Snippet>
					<Snippet
						snippetRef={snippetRef}
						clickEvent={citySnippetsClickHandler}
						cityName='milano'></Snippet>
					<Snippet
						snippetRef={snippetRef}
						clickEvent={citySnippetsClickHandler}
						cityName='napoli'></Snippet>
					<Snippet
						snippetRef={snippetRef}
						clickEvent={citySnippetsClickHandler}
						cityName='torino'></Snippet>
				</div>
				<form
					onSubmit={submitHandler}
					id='searchForm'
					className='searchFormDown navbar navbar-light bg-light fixed-top'
					style={searchFormDown === true ? {} : { display: "none" }}>
					<div className='container'>
						<span className='col-2 col-md-2 col-lg-2 py-auto'>Località:</span>
						<div className='input-group col-7 col-md-8 col-lg-8 pl-0 pr-2'>
							<input
								type='text'
								onChange={cityChangeHandler}
								className='form-control'
								id='comune-1'
								name='comune'
								placeholder='Comune'
							/>
						</div>
						<button
							type='submit'
							className='btn btn-danger col-3 col-md-2 col-lg-2'
							style={{ background: colors.bg, border: "none" }}>
							Invia
						</button>
					</div>
				</form>
				{!infos && (
					<div className='d-flex justify-content-center'>
						<div
							className='spinner-border'
							role='status'
							style={{ color: "#fc4e03" }}></div>
					</div>
				)}
				{infos && infos.cod != "404" && (
					<>
						<h2 className='text-center'>
							Meteo per {infos.city.name}
							{infos.city.country.toLowerCase() !== "it"
								? ` [${infos.city.country}]`
								: ""}
						</h2>
						{/* <div className="alert mt-4">
            <mute>Inviaci un feedback!</mute>
            <p className="h4">
              La nostra previsione è veritiera?
            </p>
            <div className="row">
              <div className="col">
                <button className="col btn feedback">sì</button>
              </div>
              <div className="col">
                <button className="col btn feedback">snì</button>
              </div>
              <div className="col">
                <button className="col btn feedback">no</button>
              </div>
            </div>
          </div> */}
						<PrevisionList key={"list"} info={infos}></PrevisionList>
					</>
				)}
				{infos && infos.cod === "404" ? (
					<div className='row'>
						<div className='column text-right'>
							<img className='w-50 mr-4 mt-2' src='/img/404.gif' alt='404 Error' />
						</div>
						<div className='column text-center'>
							<p className='h1 text-left' style={{ fontSize: "6rem" }}>
								404
							</p>
							<p className='h2 text-center'>Località non trovata</p>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
}

export default HomePage;
