import Prevision from './Prevision'

function PrevisionList(props) {
	props = props.info;

	for(let i = 0; i < props.list.length; i++) {
		const prop = props.list[i];
		const monthNames = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
					"Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"];
		const dateObj = new Date(prop.dt*1000);
		const month = monthNames[dateObj.getMonth()];
		const day = String(dateObj.getDate()).padStart(2, '0');
		const output = month  + '\n'+ day;
		props.list[i].output = output;
		props.list[i].hour = dateObj.getHours();
	}

	var lastDate = "";

	return (
		<div className="list-container">
			{
				props.list.map((prop, _index) => {
					if (prop.output !== lastDate){
						lastDate = prop.output;
						return (
							<div>
								<p className="h3">{prop.output}</p>
								<table className={"table"}>
									<thead>
										<tr>
											<th scope="col">Ora</th>
											<th scope="col"><i class="bi bi-thermometer-half"></i> Temperatura</th>
											<th scope="col"><i class="bi bi-sun"></i> Tempo</th>
											<th scope="col"><i class="bi bi-umbrella"></i> Precipitazioni</th>
											<th scope="col"><i class='bi bi-droplet'></i> Umidità</th>
											<th scope="col"><i class="bi bi-wind"></i> Vento</th>
											<th scope="col"><i class="bi bi-cloud"></i> Nuvolosità</th>
										</tr>
									</thead>
									<tbody>
									{
										props.list.filter(i => i.output === lastDate).map((info, index) => (
											<Prevision key={index} info={info}></Prevision>
										))
									}
						
									</tbody>
								</table>
							</div>
						)
					} else {
						<Prevision key={_index} info={prop}></Prevision>
					}
				})
			}
		</div>
	);
}

export default PrevisionList;