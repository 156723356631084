function CreditiPage() {
	return <div className='container'>
		<p className="h1">Crediti</p>
		Fatto da <a href="https://github.com/AndreaGennaioli">Andrea Gennaioli</a> con <img className="emoji" src="/img/react.png" alt=""/> e <img className="emoji" src="/img/bootstrap.png" alt=""/>.
		<br/>
		Questo sito funziona grazie all'API pubblica di <a href="https://openweathermap.org/api">openweathermap.org</a>.
	</div>;
}

export default CreditiPage;
