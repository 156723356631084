import colors from "../colors";
import { Link } from "react-router-dom";

function Header(props) {
	return (
		<>
			<nav className='navbar navbar-dark' style={{ background: colors.bg }}>
				<div className='container d-flex align-center justify-content-center'>
					<a
						className='navbar-brand'
						style={{marginLeft: '-50px', fontSize: "48px", fontWeight: "bold" }}
						href='/'>
							<img className='mr-2' src="/img/logo-png.png" height="50" alt="MeteOra" title="MeteOra"/>
							<span >
								MeteOra
							</span>
					</a>
				</div>
			</nav>

			<div
				className='mb-5'
				style={{ borderBottom: "1px solid black", backgroundColor: "#F8F9FA" }}>
				<ul className='nav nav-pills container py-2 pl-3'>
					{props.selected === "home" ? (
						<>
							<li className='nav-item'>
								<Link className='nav-link active' aria-current='page' to='/'>
									Home
								</Link>
							</li>
							<li className='nav-item'>
								<Link className='nav-link' to='/crediti'>
									<p>Crediti</p>
									<div></div>
								</Link>
							</li>
						</>
					) : props.selected === "crediti" ? (
						<>
							<li className='nav-item'>
								<Link className='nav-link' to='/'>
									<p>Home</p>
									<div></div>
								</Link>
							</li>
							<li className='nav-item'>
								<Link
									className='nav-link active'
									aria-current='page'
									to='/crediti'>
									Crediti
								</Link>
							</li>
						</>
					) : (
						<>
							<li className='nav-item'>
								<Link className='nav-link' to='/'>
									<p>Home</p>
									<div></div>
								</Link>
							</li>
							<li className='nav-item'>
								<Link className='nav-link' to='/crediti'>
									<p>Crediti</p>
									<div></div>
								</Link>
							</li>
						</>
					)}
					<li className='nav-item ml-auto ml-0'>

					</li>
				</ul>
			</div>
		</>
	);
}

export default Header;
