import colors from '../colors'

function Snippet(props) {
    return (
        <div className="card-f col-6 col-md-3 px-2">
          <div onClick={props.clickEvent} className="card col-12 btn" style={{background: props.snippetRef.current.toLowerCase() === props.cityName && colors.bg, color: props.snippetRef.current.toLowerCase() === props.cityName && "#fff"}} data-city={props.cityName}>
            <div className="card-body text-center" data-city={props.cityName}>
              <p className="h3 mb-0" data-city={props.cityName}>{props.cityName[0].toUpperCase()+props.cityName.slice(1)}</p>
            </div>
          </div>
        </div>
    )
}

export default Snippet;